import React from "react"
import { LayoutTabella } from "../components/layout"
import Seo from "../components/seo"
import { Breadcrumb } from "../components/toolbar"
import { SectionNameProgetti } from "../components/toolbar"
import { ToolbarProgetti } from "../components/toolbar"
import { TabellaProgetti } from "../components/tabella"

const ProgettiPage = ({idCliente = null}) => {
    const Toolbar = () => <ToolbarProgetti idCliente={idCliente}/>
    const Tabella = () => <TabellaProgetti idCliente={idCliente}/>
    const BreadcrumbProgetti = () => <Breadcrumb arrayLink={[{uri: '/progetti', titolo: 'Progetti'}]}/>

    return (
        <LayoutTabella 
            Breadcrumb={BreadcrumbProgetti} 
            SectionName={SectionNameProgetti}
            Toolbar={Toolbar} 
            Tabella={Tabella}
        >
            <Seo title="Progetti" />
        </LayoutTabella>
    )
}

export default ProgettiPage
